<template>
  <section id="section-store">
    <div class="section-store__wrap">
      <div class="section__head section-store__head">
        <h3>
          STORE
        </h3>
      </div>
      <div class="section-store__items">
        <div class="section-store__item">
          <p>GOLD’S GYM 原宿東京</p>
          <div class="section-store__item-icons">
            <div class="section-store__item-icon industry">
              <img src="../../assets/lp_new/icon_industry.png" alt="icon" />
            </div>
            <div class="section-store__item-icon glass">
              <img src="../../assets/lp_new/icon_glass.png" alt="icon" />
            </div>
          </div>
        </div>
        <div class="section-store__item">
          <p>GOLD’S GYM 原宿ANNEX</p>
          <div class="section-store__item-icons">
            <div class="section-store__item-icon industry">
              <img src="../../assets/lp_new/icon_industry.png" alt="icon" />
            </div>
            <div class="section-store__item-icon glass">
              <img src="../../assets/lp_new/icon_glass.png" alt="icon" />
            </div>
          </div>
        </div>
        <div class="section-store__item">
          <p>GOLD’S GYM 渋谷東京</p>
          <div class="section-store__item-icons">
            <div class="section-store__item-icon industry">
              <img src="../../assets/lp_new/icon_industry.png" alt="icon" />
            </div>
            <div class="section-store__item-icon glass">
              <img src="../../assets/lp_new/icon_glass.png" alt="icon" />
            </div>
          </div>
        </div>
        <div class="section-store__item">
          <p>GOLD’S GYM 表参道</p>
          <div class="section-store__item-icons">
            <div class="section-store__item-icon industry">
              <img src="../../assets/lp_new/icon_industry_not.png" alt="icon" />
            </div>
            <div class="section-store__item-icon glass">
              <img src="../../assets/lp_new/icon_glass.png" alt="icon" />
            </div>
          </div>
        </div>
        <div class="section-store__item">
          <p>GOLD’S GYM ノース東京</p>
          <div class="section-store__item-icons">
            <div class="section-store__item-icon industry">
              <img src="../../assets/lp_new/icon_industry_not.png" alt="icon" />
            </div>
            <div class="section-store__item-icon glass">
              <img src="../../assets/lp_new/icon_glass.png" alt="icon" />
            </div>
          </div>
        </div>
        <div class="section-store__item">
          <p>GOLD’S GYM 代々木上原</p>
          <div class="section-store__item-icons">
            <div class="section-store__item-icon industry">
              <img src="../../assets/lp_new/icon_industry_not.png" alt="icon" />
            </div>
            <div class="section-store__item-icon glass">
              <img src="../../assets/lp_new/icon_glass.png" alt="icon" />
            </div>
          </div>
        </div>
        <div class="section-store__item">
          <p>GOLD’S GYM 四ツ谷</p>
          <div class="section-store__item-icons">
            <div class="section-store__item-icon industry">
              <img src="../../assets/lp_new/icon_industry_not.png" alt="icon" />
            </div>
            <div class="section-store__item-icon glass">
              <img src="../../assets/lp_new/icon_glass.png" alt="icon" />
            </div>
          </div>
        </div>
        <div class="section-store__item">
          <p>GOLD’S GYM 東中野</p>
          <div class="section-store__item-icons">
            <div class="section-store__item-icon industry">
              <img src="../../assets/lp_new/icon_industry_not.png" alt="icon" />
            </div>
            <div class="section-store__item-icon glass">
              <img src="../../assets/lp_new/icon_glass.png" alt="icon" />
            </div>
          </div>
        </div>
        <div class="section-store__item">
          <p>GOLD’S GYM 銀座東京</p>
          <div class="section-store__item-icons">
            <div class="section-store__item-icon industry">
              <img src="../../assets/lp_new/icon_industry_not.png" alt="icon" />
            </div>
            <div class="section-store__item-icon glass">
              <img src="../../assets/lp_new/icon_glass.png" alt="icon" />
            </div>
          </div>
        </div>
        <div class="section-store__item">
          <p>GOLD’S GYM 銀座中央</p>
          <div class="section-store__item-icons">
            <div class="section-store__item-icon industry">
              <img src="../../assets/lp_new/icon_industry_not.png" alt="icon" />
            </div>
            <div class="section-store__item-icon glass">
              <img src="../../assets/lp_new/icon_glass.png" alt="icon" />
            </div>
          </div>
        </div>
        <div class="section-store__item">
          <p>GOLD’S GYM サウス東京</p>
          <div class="section-store__item-icons">
            <div class="section-store__item-icon industry">
              <img src="../../assets/lp_new/icon_industry_not.png" alt="icon" />
            </div>
            <div class="section-store__item-icon glass">
              <img src="../../assets/lp_new/icon_glass.png" alt="icon" />
            </div>
          </div>
        </div>
        <div class="section-store__item">
          <p>GOLD’S GYM ウエスト東京</p>
          <div class="section-store__item-icons">
            <div class="section-store__item-icon industry">
              <img src="../../assets/lp_new/icon_industry_not.png" alt="icon" />
            </div>
            <div class="section-store__item-icon glass">
              <img src="../../assets/lp_new/icon_glass.png" alt="icon" />
            </div>
          </div>
        </div>
        <div class="section-store__item">
          <p>GOLD’S GYM イースト東京</p>
          <div class="section-store__item-icons">
            <div class="section-store__item-icon industry">
              <img src="../../assets/lp_new/icon_industry_not.png" alt="icon" />
            </div>
            <div class="section-store__item-icon glass">
              <img src="../../assets/lp_new/icon_glass.png" alt="icon" />
            </div>
          </div>
        </div>
        <div class="section-store__item">
          <p>GOLD’S GYM サウス東京 ANNEX</p>
          <div class="section-store__item-icons">
            <div class="section-store__item-icon industry">
              <img src="../../assets/lp_new/icon_industry_not.png" alt="icon" />
            </div>
            <div class="section-store__item-icon glass">
              <img src="../../assets/lp_new/icon_glass.png" alt="icon" />
            </div>
          </div>
        </div>
      </div>
      <div class="section-store__items-discs">
        <div class="section-store__items-disc left">
          <img src="../../assets/lp_new/icon_industry_desc.png" alt="icon" />
          <p>
            GX FACTORY 設置店
          </p>
        </div>
        <div class="section-store__items-disc right">
          <img src="../../assets/lp_new/icon_glass_desc.png" alt="icon" />
          <p>
            GX PACK MINI設置店
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style>
/* -=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=- */
/* section-store */
/* -=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=- */
#section-store {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding: 100px 0 100px;
  background-image: url("../../assets/lp_new/section_store_background.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-size: contain;
  background-size: cover;
}

.section-store__wrap {
  width: 100%;
  max-width: 1020px;
}

.section-store__items {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 30px auto;
}

.section-store__item {
  width: calc(33.333% - 20px);
  border-radius: 10px;
  margin: 15px 10px;
  background: #a69c7d;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  padding: 10px 20px;
}

.section-store__item p {
  margin: 0 10px 0 0;
  color: #060606;
  font-size: 16px;
  font-weight: bold;
}

.section-store__item-icons {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
}

.section-store__item-icon.industry {
  width: 24px;
  margin-right: 12px;
}

.section-store__item-icon.glass {
  width: 20px;
}

.section-store__item-icon img {
  width: 100%;
}

.section-store__items-discs {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
}

.section-store__items-disc {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
}

.section-store__items-disc.left {
  margin-right: 40px;
}

.section-store__items-disc img {
  width: 48px;
  margin-right: 30px;
}

.section-store__items-disc p {
  margin: 0;
  color: #a69c7d;
  font-size: 14px;
  font-weight: bold;
}

@media screen and (max-width: 1020px) {
  .section-store__wrap {
    max-width: 700px;
  }
  .section-store__item {
    width: calc(50% - 20px);
  }
}
@media screen and (max-width: 768px) {
  #section-store {
    padding: 50px 0 50px;
  }

  .section-store__wrap {
    width: 100%;
    padding: 0 16px;
  }

  .section-store__item {
    width: calc(50% - 10px);
    margin: 7px 5px;
    padding: 10px 10px;
  }

  .section-store__item p {
    font-size: 12px;
  }

  .section-store__item-icon.industry {
    width: 12px;
    margin-right: 12px;
  }

  .section-store__item-icon.glass {
    width: 10px;
  }

  .section-store__items-discs {
    margin: 30px 0 0 10px;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .section-store__items-disc.left {
    margin-right: 30px;
  }

  .section-store__items-disc img {
    width: 24px;
    margin-right: 15px;
  }
}
@media screen and (max-width: 480px) {
}
</style>
